import React from 'react';
import './BuyNowModal.css';
import logo from './thgo2.jpg';


const BuyNowModal = (props) => {
  
  const {show, closeModal, activePlan} = props;
    return (
    show?<div  id='modal-overlay'>
      <div id='modal-pop'>
     <div className='pop-header'>
      <img className='popUp-logo' src={logo} alt='logo'/>
     <button className='close-modal-button'
    onClick={closeModal}
    >x</button>
    <h1 style={{'marginTop':'50px'}}>TASK.HELP</h1>
    <h6>Payment for ${activePlan?.metadata?.planRate}</h6>
     </div>
    <div className='form-container'>{props.children}</div>
    </div>
   </div>:null
    
  )
}
export default BuyNowModal;