import { CardCvcElement, CardElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react';
import { Calendar, CheckCircle, CreditCard, Lock, Mail } from 'react-feather';
import { RotatingLines } from 'react-loader-spinner';
import './CheckoutForm.css'
const CheckoutForm = ({ planData, showUpgrade }) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  console.log('product from checkout', planData);


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (!emailRegex.test(email)) {
      setMessage('Please enter a valid email address');
      return;
    }
    const Stripecard = elements.getElement(CardNumberElement);
    console.log('i am card', Stripecard);
    if (Stripecard == null) {
      return;
    }

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: Stripecard,
    });

    if (error) {
      setMessage(error.message)
      console.log('[error]', error);
      return;
    } else {
      console.log('[PaymentMethod]', paymentMethod);
    }

    //get all customer
    setIsLoading(true);
    const allProducts = await fetch('http://itechub.online/Allproducts').then(res => res.json());
    const allcustomers = await fetch('http://itechub.online/allCustomer').then(res => res.json());
    console.log('here have all customer', allcustomers);

    // get all subscription
    const subscriptions = await fetch('http://itechub.online/allSubscriptions').then(res => res.json());
    console.log('all subscriptions here', subscriptions);

    // check existing user for subscription
    const cus_data = allcustomers.data.find((cus) => cus.email === email)
    console.log('customer email', cus_data);
    if (cus_data) {
      const subscribed_user = subscriptions.data.find((sub) => sub.customer === cus_data.id);
      // console.log('here subscribed user price',subscribed_user);
      // console.log('active plan price',planData.default_price);

      const subscribed_plan = allProducts.data.find((p) => p.default_price === subscribed_user.plan.id);
      console.log('subscribed plan:', subscribed_plan?.metadata?.planRate);
      console.log('product plan:', planData?.metadata?.planRate);
      const clickedFor = parseInt(planData?.metadata?.planRate);
      const subscribed = parseInt( subscribed_plan?.metadata?.planRate);
      if (clickedFor>subscribed) {
        showUpgrade(subscribed_user);
        setIsLoading(false);
        return;
      }
      if(subscribed_user){
        setMessage(`You Already subscribed ${subscribed_plan.name}`);
        setIsLoading(false);
        return;
      }
      //else{
      //   setMessage('lets go for update plan');
      //   const update_subscription = await fetch('http://itechub.online/update-subscription',{

      //     method : "POST",
      //     headers :{
      //       'content-type' : 'application/json'
      //     },
      //     body : JSON.stringify({
      //       subId: subscribed_user.id,
      //       order_id:planData.metadata.order_id
      //     })
      //   }).then(res => res.json());
      //   console.log('update sub res',update_subscription);
      //   setMessage('subscription update successfully')
      //   setIsLoading(false);
      //   return;
      // }
    }
    const customer = await fetch('http://itechub.online/create-customer', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email: email,
        description: `test customer id:${planData.id}`
      })
    }).then(res => res.json());
    console.log('customer created', customer);
    if (customer) {
      setMessage('');
      const subscription = await fetch('http://itechub.online/create-subscription', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          customerId: customer.id,
          priceId: planData.default_price,
        })
      }).then(res => res.json());
      console.log('subscription object', subscription);
      if (subscription) {
        const { id, client_secret } = await fetch('http://itechub.online/create-paymentIntent', {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            payment_method_id: paymentMethod.id,
            amount: planData.metadata.planRate,
          })
        }).then(res => res.json())
        console.log('log from intent', id, client_secret);
        setMessage('Congratulations!! Your subscription compleated')
        setIsLoading(false);
        setButtonDisable(true);
      }
    }
  }

  //style for cardElement 
  const cardStyle = {
    style: {
      base: {
        color: 'black',
        fontWeight: '500',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '22px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
          color: '#fce883',
        },
        '::placeholder': {
          color: 'gray'
        },
      },
      invalid: {
        iconColor: 'red',
        color: 'red',
      },
    }
  };

  return (
    <div>
      <form id='checkout-form' onSubmit={handleSubmit}>
        <div className='input-email-container'>
          <Mail size={24} />
          <input type='email' id="email" placeholder='Email' onChange={(e) => setEmail(e.target.value)} required />
        </div>
        <div className='card-number-container'>
          <CreditCard size={24} />
          <CardNumberElement options={cardStyle} id='card-number-element' />
        </div>
        <div className='date-cvc-container'>
          <div className='exp-container'>
            <Calendar size={24} />
            <CardExpiryElement options={cardStyle} id='card-expiry-element' />
          </div>
          <div className='cvc-container'>
            <Lock size={24} />

            <CardCvcElement options={cardStyle} id='card-cvc-element' />
          </div>
        </div>
        <button className={`submit-button ${buttonDisable && 'submit-success'}`} type='submit' disabled={!stripe || !elements || buttonDisable}>
          {
            isLoading === true ? <RotatingLines
              strokeColor='white' strokeWidth="5"
              animationDuration="0.75"
              width="56"
              visible={true} /> : buttonDisable ? <CheckCircle size={40} /> : `pay $${planData?.metadata?.planRate}`
          }
        </button>
        {
          message ? <h4>{message}</h4> : <></>
        }
      </form>
    </div>
  )
}
export default CheckoutForm;
