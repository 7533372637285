import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useRef, useState } from 'react'
import {Col, Row } from 'react-bootstrap';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import BuyNowModal from './BuyNowModal';
import './Plans.css'
const stripePromise = loadStripe('pk_test_51MijM4KQc71cF0eXFHYeS7GGfHnm0NoxiGaETg5AEHfsZpPbFHcZMxPM8gWFbmmBDtgwwoiEWtPWEt8K6FOhaWaz00O87iaZbQ');
const Plans = () => {
    const [products,setProducts] = useState([]);
    const [activePlan, setActivePlan] = useState({})
    const [showModal, setShowModal] = useState(false);
    const [showMessage, setShowMessage] = useState (false);
    const planCatagory = ['ENTERPRISE','ENTERPRISE','ENTERPRISE','BUSINESS','BUSINESS','BASIC','BASIC','STARTER','STARTER','STARTER']
    // fetch for products data 
    useEffect(()=>{
        fetch('http://itechub.online/Allproducts')
        .then(res=>res.json())
        .then(result=>setProducts(result.data))
       
    },[])
    console.log(products);
    const handleBuyNow = (p) => {
        setShowModal(true);
        setActivePlan(p);
    }
    const closeModal = () =>{
      setShowModal(false)
    }
    const showUpgrade = (subscription)=>{
      setShowModal(false);
      setShowMessage(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
    }
  return (
    <>
    {
    showMessage?<div id='upgrade-box' className='upgrade-message-container'>
      <button>upgrade</button>
      <h3>Upgrade enables you to increase number of emails you can send. At the end of the cycle we use the combine email allowance total of any plan you upgrade to calculate you sent and you last upgrade becomes your effective plan.
</h3>
    </div>:<></>
    }
    <div className='plan-container'>
      <h1>plan working staticaly</h1>
            {
             
            products.map((p,i)=>{
                return( 
                  <div className="single-plan">
                    <h4 className={`planCatagory ${planCatagory[i]==='ENTERPRISE'&&'enterprise'} ${planCatagory[i]==='BUSINESS'&&'business'} ${planCatagory[i]==='BASIC'&&'basic'} ${planCatagory[i]==='STARTER'&&'starter'}`}
                    
                    >{planCatagory[i]}</h4>
                    <h1>{p?.name}</h1>
                     <h2>{p?.description}</h2>
      
                     <button
                      className='buyNow-button'
                      onClick={() => handleBuyNow(p)}
                     >BuyNow
                     </button>
                     <div className="plan-info">
                       <p>Averages rates</p>
                       <p>{p?.unit_label}</p>
                     </div>
                     <div className="plan-info">
                       <p>Plan Rate</p>
                       <p>${p?.metadata?.planRate}/mo.</p>
                     </div>
                    </div>
                  )
            })}
            
            <BuyNowModal
            show={showModal} 
            closeModal = {closeModal}
            activePlan={activePlan}
            >
              <Elements stripe={stripePromise}>
                <CheckoutForm
                 planData={activePlan}
                 showUpgrade = {showUpgrade}/>
              </Elements>
            </BuyNowModal>
    </div>
    </>
  )
}

export default Plans;