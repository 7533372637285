import logo from './logo.svg';
import './App.css';
import Plans from './components/Plans/Plans';
import { Routes , Route} from 'react-router-dom';




function App() {
  return (
    <div className="App">
    
     <Routes>
      <Route path='/' element={<Plans/>}/>
     </Routes>
    </div>
  );
}

export default App;
